@include at-media("tablet-lg") {
  .with-inline-navigation .usa-prose {
    // keep other elements from overlapping in-page navigation
    .usa-alert,
    table,
    h2,
    h3,
    h4 {
      max-width: units("tablet");
    }
  }
}

.usa-layout-docs__main {
  border-top: 1px solid #dfe1e2;
}

.usa-prose.usa-prose__home {
  width: 100%;
}

h1.margin-top-1 {
  margin-top: 0.5rem;
}

.usa-summary-box {
  max-width: 68ex;
}

.usa-prose > ul li, .usa-prose > ol li {
  max-width:80ex;
}

.usa-alert__text {
  :first-child {
    margin-top: 0;
  }

  :last-child {
    margin-bottom: 0;
  }
}
